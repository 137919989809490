import http from "../../utils/http"

/**
 * 获取扶贫目录
 * @param {array} params
 */
export function getFupinproductsList (params) {
  return http({
    url: "/api/fupinProduct/getFupinproductsList",
    data: params
  }, 10000);
}
/**
 * 扶贫目录批次列表
 * @param {array} params
 */
export function getFupinProductsBatchList (params) {
  return http({
    url: "/api/fupinProduct/getFupinProductsBatchList",
    data: params
  }, 10000);
}



/**
 * 获取全部扶贫产品统计
 * @param {*} params 
 * @returns 
 */
export function getStatisticsFupinproducts(params){
  return http({
    url:"api/fupinProduct/getStatisticsFupinproducts",
    data:params,
  },10000);
}


/**
 * 分区县采购数据汇总
 * @param {*} params 
 * @returns 
 */
export function getPurchaseGoodsSummary(params){
  return http({
    url:"api/purchaseGoodsSummary/getPurchaseGoodsSummary",
    data:params,
  },10000)
}



/**
 * 全省各市州采购排行
 * @param {*} params 
 * @returns 
 */
export function getPurchaseGoodsSummaryList(params){
  return http({
    url:"/api/purchaseGoodsSummary/getPurchaseGoodsSummaryList",
    data:params,
  },10000)
}

/**
 * 全省预算单位或非预算单位的采购排行
 * @param {*} params 
 * @returns 
 */
export function getPurchasingUnitRankingList(params){
  return http({
    url:"api/purchasingUnitRanking/getPurchasingUnitRankingList",
    data:params,
  },10000)
}

/**
 * 市州或区县销售数据排行搒
 * @param {*} params 
 * @returns 
 */
export function getSalesGoodsSummaryList(params){
  return http({
    url:"api/salesGoodsSummary/getSalesGoodsSummaryList",
    data:params,
  },10000)
}
