<template>
  <div class="mapArea_conter" style="width: 100%; height: 100%; position: relative">
    <div id="mapid" class="mapStyle"></div>
  </div>
</template>
<script>
  import json_hunan from "../../../static/map/province/hunan.json";
  import json_changde from "../../../static/map/province/hunan/changde.json";
  import json_changsha from "../../../static/map/province/hunan/changsha.json";
  import json_chengzhou from "../../../static/map/province/hunan/chengzhou.json";
  import json_henyang from "../../../static/map/province/hunan/henyang.json";
  import json_huaihua from "../../../static/map/province/hunan/huaihua.json";
  import json_loudi from "../../../static/map/province/hunan/loudi.json";
  import json_shaoyang from "../../../static/map/province/hunan/shaoyang.json";
  import json_xiangtan from "../../../static/map/province/hunan/xiangtan.json";
  import json_xiangxi from "../../../static/map/province/hunan/xiangxi.json";
  import json_yiyang from "../../../static/map/province/hunan/yiyang.json";
  import json_yongzhou from "../../../static/map/province/hunan/yongzhou.json";
  import json_yueyang from "../../../static/map/province/hunan/yueyang.json";
  import json_zhangjiajie from "../../../static/map/province/hunan/zhangjiajie.json";
  import json_zhuzhou from "../../../static/map/province/hunan/zhuzhou.json";
  export default {
    props: {
      hunan_qx: {
        type: Array,
        default: []
      },
      cityData: {
        type: Array,
        default: []
      },
    },
    data() {
      return {
        timer: "",
        currentmap: "hunan",
        option: {
          tooltip: {
            trigger: "item",
            extraCssText: "z-index: 1",
            formatter: function (params) {
              if (params.seriesIndex == 0) {
                if (params.value >= 10000) {
                  // return ("<div class='tooltip'>" + params.name + "<br />平台销售额：" + (parseInt(params.data
                  //     .economic_total_produce) / 10000).toFixed(2) + "万元" + "<br/>" + "供应商个数" + parseInt(params.data
                  //     .house_number) + "" + "<br/>" + "带贫人口：" + parseInt(params.data.help_person_total) + "人" +
                  //   "<br/>" + "帮扶产品：" + parseInt(params.data.poor_person_total) + "款" + "</div>");
                  return ("<div class='tooltip'>" + params.name + "</div>");
                } else {
                  // params.name + "<br />暂无信息";
                  params.name;
                }
                // return params.name + "<br />暂无信息";
                return params.name;
              } else {
                if (params.value[2] >= 10000) {
                  // return params.name + "<br />暂无信息";
                  return params.name;
                } else {
                  // return params.name + "<br />暂无信息";
                  return params.name;
                }
              }
            },
            textStyle: {
              fontSize: 12,
            },
          },
        },
        mapdata: [],
        // cityData: [],
        cityList1: [],
        cityName: "",
        mapParameterData: {
          areaCode: "450000000000",
          level: 1,
        },
        back: false,
        Grade: 1,
        codeList: ["450000000000"],
        loading: "",
      };
    },
    mounted() {
      this.mapChart(this.currentmap);
      console.log("....hunan...show...cityData...",this.cityData);
    },
    watch: {},
    methods: {
      //获取省级地图数据
      mapChart(currentmap) {
        this.myMapChart = this.$echarts.init(document.getElementById("mapid"));
        this.myMapChart.showLoading();
        // 这是组件开始初始化时需要拉去的数据--中国地图的数据
        let data = json_hunan;
        let cityData = [];
        switch (currentmap) {
          case 'hunan':
            data = json_hunan;
            break;
          case 'changsha':
            data = json_changsha;
            break;
          case 'changde':
            data = json_changde;
            break;
          case 'chengzhou':
            data = json_chengzhou;
            break;
          case 'henyang':
            data = json_henyang;
            break;
          case 'huaihua':
            data = json_huaihua;
            break;
          case 'loudi':
            data = json_loudi;
            break;
          case 'shaoyang':
            data = json_shaoyang;
            break;
          case 'xiangtan':
            data = json_xiangtan;
            break;
          case 'xiangxi':
            data = json_xiangxi;
            break;
          case 'yiyang':
            data = json_yiyang;
            break;
          case 'yongzhou':
            data = json_yongzhou;
            break;
          case 'yueyang':
            data = json_yueyang;
            break;
          case 'zhangjiajie':
            data = json_zhangjiajie;
            break;
          case 'zhuzhou':
            data = json_zhuzhou;
            break;
        }
        // cityData = [{
        //   cityName: "常德市",
        //   donateFunds: 304803,
        //   publishItemsCount: 11147,
        //   publishSuccessItemsCount: 7619,
        //   recipientFunds: 1252011,
        //   economic_total_produce: "168359.00",
        //   agricultural_land: "0",
        //   help_person_total: "42",
        //   poor_person_total: "23",
        //   economic_total: "186359.00",
        //   employment_number: "163",
        //   per_capita_income: "1032.88",
        //   house_number: 139,
        //   registedUserCount: 535030,
        // }, {
        //   cityName: "岳阳市",
        //   economic_total_produce: "0.00",
        //   agricultural_land: "0",
        //   help_person_total: "0",
        //   poor_person_total: "0",
        //   economic_total: "0.00",
        //   employment_number: "0",
        //   per_capita_income: "0.00",
        //   house_number: 0,
        //   donateFunds: 73285,
        //   publishItemsCount: 2086,
        //   publishSuccessItemsCount: 1744,
        //   recipientFunds: 325272,
        //   registedUserCount: 421760,
        // }, {
        //   cityName: "益阳市",
        //   economic_total_produce: "0.00",
        //   agricultural_land: "0",
        //   help_person_total: "0",
        //   poor_person_total: "0",
        //   economic_total: "0.00",
        //   employment_number: "0",
        //   per_capita_income: "0.00",
        //   house_number: 0,
        //   donateFunds: 35129,
        //   publishItemsCount: 1827,
        //   publishSuccessItemsCount: 686,
        //   recipientFunds: 508287,
        //   registedUserCount: 114402,
        // }, {
        //   cityName: "长沙市",
        //   economic_total_produce: "0.00",
        //   agricultural_land: "0",
        //   help_person_total: "0",
        //   poor_person_total: "0",
        //   economic_total: "0.00",
        //   employment_number: "0",
        //   per_capita_income: "0.00",
        //   house_number: 0,
        //   donateFunds: 98694,
        //   publishItemsCount: 1809,
        //   publishSuccessItemsCount: 335,
        //   recipientFunds: 439521,
        //   registedUserCount: 410124,
        // }, {
        //   cityName: "湘潭市",
        //   economic_total_produce: "0.00",
        //   agricultural_land: "0",
        //   help_person_total: "0",
        //   poor_person_total: "0",
        //   economic_total: "0.00",
        //   employment_number: "0",
        //   per_capita_income: "0.00",
        //   house_number: 0,
        //   donateFunds: 20170,
        //   publishItemsCount: 1349,
        //   publishSuccessItemsCount: 189,
        //   recipientFunds: 239691,
        //   registedUserCount: 141983,
        // }, {
        //   cityName: "株洲市",
        //   economic_total_produce: "0.00",
        //   agricultural_land: "0",
        //   help_person_total: "0",
        //   poor_person_total: "0",
        //   economic_total: "0.00",
        //   employment_number: "0",
        //   per_capita_income: "0.00",
        //   house_number: 0,
        //   donateFunds: 56490,
        //   publishItemsCount: 1093,
        //   publishSuccessItemsCount: 706,
        //   recipientFunds: 579744,
        //   registedUserCount: 139250,
        // }, {
        //   cityName: "衡阳市",
        //   economic_total_produce: "0.00",
        //   agricultural_land: "0",
        //   help_person_total: "0",
        //   poor_person_total: "0",
        //   economic_total: "0.00",
        //   employment_number: "0",
        //   per_capita_income: "0.00",
        //   house_number: 0,
        //   donateFunds: 106421,
        //   publishItemsCount: 864,
        //   publishSuccessItemsCount: 571,
        //   recipientFunds: 633179,
        //   registedUserCount: 124964,
        // }, {
        //   cityName: "郴州市",
        //   economic_total_produce: "0.00",
        //   agricultural_land: "0",
        //   help_person_total: "0",
        //   poor_person_total: "0",
        //   economic_total: "0.00",
        //   employment_number: "0",
        //   per_capita_income: "0.00",
        //   house_number: 0,
        //   donateFunds: 71875,
        //   publishItemsCount: 543,
        //   publishSuccessItemsCount: 285,
        //   recipientFunds: 164599,
        //   registedUserCount: 335905,
        // }, {
        //   cityName: "永州市",
        //   economic_total_produce: "0.00",
        //   agricultural_land: "0",
        //   help_person_total: "0",
        //   poor_person_total: "0",
        //   economic_total: "0.00",
        //   employment_number: "0",
        //   per_capita_income: "0.00",
        //   house_number: 0,
        //   donateFunds: 21492,
        //   publishItemsCount: 492,
        //   publishSuccessItemsCount: 256,
        //   recipientFunds: 484741,
        //   registedUserCount: 160148,
        // }, {
        //   cityName: "邵阳市",
        //   economic_total_produce: "0.00",
        //   agricultural_land: "0",
        //   help_person_total: "0",
        //   poor_person_total: "0",
        //   economic_total: "0.00",
        //   employment_number: "0",
        //   per_capita_income: "0.00",
        //   house_number: 0,
        //   donateFunds: 213303,
        //   publishItemsCount: 463,
        //   publishSuccessItemsCount: 225,
        //   recipientFunds: 211046,
        //   registedUserCount: 911227,
        // }, {
        //   cityName: "娄底市",
        //   economic_total_produce: "0.00",
        //   agricultural_land: "0",
        //   help_person_total: "0",
        //   poor_person_total: "0",
        //   economic_total: "0.00",
        //   employment_number: "0",
        //   per_capita_income: "0.00",
        //   house_number: 0,
        //   donateFunds: 51410,
        //   publishItemsCount: 400,
        //   publishSuccessItemsCount: 320,
        //   recipientFunds: 298702,
        //   registedUserCount: 176980,
        // }, {
        //   cityName: "怀化市",
        //   economic_total_produce: "0.00",
        //   agricultural_land: "0",
        //   help_person_total: "0",
        //   poor_person_total: "0",
        //   economic_total: "0.00",
        //   employment_number: "0",
        //   per_capita_income: "0.00",
        //   house_number: 0,
        //   donateFunds: 45769,
        //   publishItemsCount: 396,
        //   publishSuccessItemsCount: 268,
        //   recipientFunds: 152479,
        //   registedUserCount: 208529,
        // }, {
        //   cityName: "张家界市",
        //   economic_total_produce: "0.00",
        //   agricultural_land: "0",
        //   help_person_total: "0",
        //   poor_person_total: "0",
        //   economic_total: "0.00",
        //   employment_number: "0",
        //   per_capita_income: "0.00",
        //   house_number: 0,
        //   donateFunds: 42169,
        //   publishItemsCount: 344,
        //   publishSuccessItemsCount: 193,
        //   recipientFunds: 260440,
        //   registedUserCount: 355428,
        // }, {
        //   cityName: "湘西土家族苗族自治州",
        //   economic_total_produce: "0.00",
        //   agricultural_land: "0",
        //   help_person_total: "0",
        //   poor_person_total: "0",
        //   economic_total: "0.00",
        //   employment_number: "0",
        //   per_capita_income: "0.00",
        //   house_number: 0,
        //   donateFunds: 22334,
        //   publishItemsCount: 213,
        //   publishSuccessItemsCount: 94,
        //   recipientFunds: 111440,
        //   registedUserCount: 24595,
        // }, ];
        this.mapdata = [];
        var _that = this;
        // 动态显示tootip
        var faultByHourIndex = 0;
        //播放所在下标
        // 销毁之前的定时器
        clearTimeout(_that.timer);
        _that.timer = '';
        _that.timer = setInterval(function () {
          // 取消之前高亮的图形
          _that.myMapChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: faultByHourIndex
          });
          faultByHourIndex++;
          if (faultByHourIndex >= 14) {
            faultByHourIndex = 0;
          }
          //使得tootip每隔几秒自动显示
          _that.myMapChart.dispatchAction({
            type: "highlight", // 根据 tooltip 的配置项显示提示框。
            seriesIndex: 0,
            dataIndex: faultByHourIndex,
          });
          _that.myMapChart.dispatchAction({
            type: "showTip", // 根据 tooltip 的配置项显示提示框。
            seriesIndex: 0,
            dataIndex: faultByHourIndex,
          });
        }, 5000);
        let d = [];
        for (let i = 0; i < data.features.length; i++) {
          for (let j = 0; j < this.cityData.length; j++) {
            if (data.features[i].properties.name === this.cityData[j].cityName) {
              let item = this.cityData[j];
              d.push({
                name: data.features[i].properties.name,
                id: data.features[i].id,
                value: item.registedUserCount,
                publishItemsCount: item.publishItemsCount,
                publishSuccessItemsCount: item.publishSuccessItemsCount,
                recipientFunds: item.recipientFunds,
                economic_total_produce: parseInt(item.economic_total_produce),
                agricultural_land: parseInt(item.agricultural_land),
                help_person_total: parseInt(item.help_person_total),
                poor_person_total: parseInt(item.poor_person_total),
                economic_total: parseInt(item.economic_total),
                employment_number: parseInt(item.employment_number),
                per_capita_income: parseInt(item.per_capita_income),
                house_number: parseInt(item.house_number),
              });
            }
          }
        }
        this.$echarts.registerMap(currentmap, data);
        this.renderMap(currentmap, d);
      },
      //渲染地图方法
      renderMap(map, data) {
        // 初始化绘制全国地图配置
        var points = [
          // {value: [113.0823, 28.2568], itemStyle: {color: '#f15548'}, num: 800},
          // {value: [113.0823, 27.2568], itemStyle: {color: '#0a8cfe'}, num: 800},
          // {value: [112.0823, 27.2568], itemStyle: {color: '#3ccc9b'}, num: 800},
          // {value: [111.0823, 27.2568], itemStyle: {color: '#fca546'}, num: 800},
          // {value: [112.1823, 26.2568], itemStyle: {color: '#0a8cfe'}, num: 800},
          // {value: [113.2823, 26.2568], itemStyle: {color: '#f15548'}, num: 800},
          // {value: [111.2823, 28.1568], itemStyle: {color: '#3ccc9b'}, num: 800},
          // {value: [112.2823, 28.1568], itemStyle: {color: '#0a8cfe'}, num: 800},
        ];
        var labelShow = true;
        (this.option.geo = {
          map: map,
          aspectScale: 0.75,
          zoom: 1.5,
          label: {
            normal: {
              show: false,
              color: "#fff",
            },
            emphasis: {
              show: false,
              color: "#fff",
            },
          },
          itemStyle: {
            normal: {
              areaColor: "none",
              borderColor: "none",
            },
            emphasis: {
              areaColor: "none",
            },
          },
        }),
        (this.option.series = [{
          name: map,
          type: "map",
          zoom: 1.2,
          mapType: map,
          roam: false,
          aspectScale: 0.9, //长宽比
          // width: '350',
          // height: '350',
          data: data,
          label: {
            normal: {
              show: labelShow,
              textStyle: {
                color: "#ffffff",
                fontSize: 12,
              },
            },
            emphasis: {
              show: false,
              textStyle: {
                color: "#fff",
                fontSize: 14,
              },
            },
          },
          itemStyle: {
            normal: {
              // 配置地图颜色
              areaColor: "#59c4e6",
              // 地图分隔线
              borderColor: "#b0eeff",
              borderWidth: 1,
            },
            emphasis: {
              // 鼠标悬停时地图颜色
              areaColor: "#c12e34",
            },
          },
        }, {
          type: "effectScatter",
          coordinateSystem: "geo",
          showEffectOn: "render",
          zlevel: 1,
          rippleEffect: {
            period: 15,
            scale: 4,
            brushType: "fill",
          },
          hoverAnimation: true,
          label: {
            normal: {
              formatter: "{b}",
              position: "right",
              offset: [15, 0],
              color: "#ef5648",
              show: true,
            },
          },
          itemStyle: {
            normal: {
              color: "#ef5648",
              shadowBlur: 10,
              shadowColor: "#333",
            },
          },
          symbolSize: 12,
          data: points,
        }, ]);
        // 渲染地图
        this.myMapChart.setOption(this.option);
        let self = this;
        this.myMapChart.hideLoading();
        this.myMapChart.off("click");
        //点击事件,根据点击区域触发
        this.myMapChart.on("click", function (params) {
          if (params.seriesType === "map") {
            let currentCity = '';
            self.hunan_qx.forEach((item, index) => {
              if (params.name === item.title) {
                currentCity = params.name;
                self.currentmap = item.pingying;
                self.$parent.changeCurrentCity(currentCity);
                self.mapChart(self.currentmap);
              }
            });
          }
        });
        window.addEventListener("resize", function () {
          self.myMapChart.resize();
        });
      },
    },
  };
</script>
<style scoped type="text/scss" lang="scss"> .mapArea_conter .mapStyle {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.tooltip{
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
</style>