<template>
  <div class="page_body">
    <div class="box">
      <div class="box_title">
        <p class="title_p">消费帮扶好成绩</p>
      </div>
      <div class="content">
        <div class="ct_position_top" v-if="position_top">
          <div class="ct_t_left">
            <el-breadcrumb separator="/" class="item_bread">
              <el-breadcrumb-item @click.native="clickMapInit('top')" class="tap_span tap_span_all">湖南省
              </el-breadcrumb-item>
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link"> {{current_city}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :icon="item.currentOn == 1 ? 'el-icon-check':''"
                    :class="item.currentOn == 1 ?'currentClass':''" :command="item.pingying"
                    v-for="(item,index) in hunan_qx" :key="index"> {{item.title}} </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-breadcrumb>
          </div>
          <div class="ct_t_msg">
            <div class="item" v-for="(item,index) in bigDataTop" :key="index">
              <p class="names">{{item.name}}</p>
              <p class="number">{{item.value}}</p>
              <p class="names">{{item.untis}}</p>
            </div>
          </div>
        </div>
        <!-- 省全部数据 -->
        <div class="content_top" v-loading="loading">
          <div class="ct_title">
            <div class="ct_t_title">全省概览</div>
            <div class="ct_t_content">
              <div class="ct_t_msg">
                <div class="item" v-for="(item,index) in bigDataTopAll" :key="index">
                  <p class="names">{{item.name}}</p>
                  <p class="number">{{item.value}}</p>
                  <p class="names">{{item.untis}}</p>
                </div>
              </div>
              <div class="ct_t_a">
                <el-button type="warning" size="mini" round @click="toPage('fupin_catalog')">目录</el-button>
              </div>
            </div>
          </div>
          <div class="ct_body">
            <el-breadcrumb separator="/" class="item_bread">
              <el-breadcrumb-item @click.native="clickMapInit" class="tap_span tap_span_all">湖南省</el-breadcrumb-item>
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link"> {{current_city}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :icon="item.currentOn == 1 ? 'el-icon-check':''"
                    :class="item.currentOn == 1 ?'currentClass':''" :command="item.pingying"
                    v-for="(item,index) in hunan_qx" :key="index"> {{item.title}} </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-breadcrumb>
            <div class="content_body">
              <div class="body_l">
                <hunan ref="mapbox" :hunan_qx="hunan_qx" :cityData="mapData.showList"></hunan>
              </div>
              <div class="body_r">
                <div class="item" v-for="(item,index) in bigDataTop" :key="index">
                  <p class="number">{{item.value}}</p>
                  <p class="names">{{item.name}}{{item.untis}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 全省各市州采购排行 -->
        <div class="content_title" v-if="!loading">
          <div class="c_title">
            <div class="c_title_l">
              <span>{{current_city}}</span>按区域采购排行
              <el-image class="title_img" :src="$img(titlesImg)" fit="cover" />
            </div>
          </div>
          <!-- 
            area_name: "岳阳"
            commodity_value_quantity: "587691"
            poverty_alleviation_product_count: "1114"
            supplier_count: "190"
            total_purchase_amount: "2837889.65"
            treat_the_poor_count: "216363"
          -->
          <div class="c_body">
            <el-table :data="tableCaigouData" style="width: 100%">
              <el-table-column align="center" prop="address" label="地区" width="220">
                <template slot-scope="scope">
                  <div class="table_address_body">
                    <span v-if="scope.$index === 0" class="title_num num1">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index === 1" class="title_num num2">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index === 2" class="title_num num3">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index > 2" class="title_num num0">{{scope.$index + 1}}</span>
                    <span>&nbsp;&nbsp;</span>
                    <span v-html="scope.row.area_name"></span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="supplier_count" label="供应商（个）">
              </el-table-column>
              <el-table-column align="center" prop="treat_the_poor_count" label="带贫人数" width="200">
              </el-table-column>
              <el-table-column align="center" prop="poverty_alleviation_product_count" label="产品数（*）" width="200">
              </el-table-column>
              <el-table-column align="center" prop="commodity_value_quantity" label="商品价值量（万元）" width="200">
              </el-table-column>
              <el-table-column align="center" prop="total_purchase_amount" label="采购金额" width="200">
              </el-table-column>
            </el-table>
            <div class="content_footer">
              <el-button round @click="toPage('city_purchase')">查看更多</el-button>
            </div>
          </div>
        </div>
        <!-- 全省各市州销售排行 -->
        <div class="content_title" v-if="!loading">
          <div class="c_title">
            <div class="c_title_l">
              <span>{{current_city}}</span>按区域销售排行
              <el-image class="title_img" :src="$img(titlesImg)" fit="cover" />
            </div>
          </div>
          <!-- 
            area_name: "岳阳"
            commodity_value_quantity: "11119419"
            poverty_alleviation_product_count: "2273"
            supplier_count: "37"
            total_sales_amount: "2721559.30"
            treat_the_poor_count: "430514"
            -->
          <div class="c_body">
            <el-table :data="tableXiaoshouData" style="width: 100%">
              <el-table-column align="center" prop="address" label="地区" width="220">
                <template slot-scope="scope">
                  <div class="table_address_body">
                    <span v-if="scope.$index === 0" class="title_num num1">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index === 1" class="title_num num2">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index === 2" class="title_num num3">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index > 2" class="title_num num0">{{scope.$index + 1}}</span>
                    <span>&nbsp;&nbsp;</span>
                    <span v-html="scope.row.area_name"></span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="supplier_count" label="供应商（个）">
              </el-table-column>
              <el-table-column align="center" prop="treat_the_poor_count" label="带贫人数" width="200">
              </el-table-column>
              <el-table-column align="center" prop="poverty_alleviation_product_count" label="产品数（*）" width="200">
              </el-table-column>
              <el-table-column align="center" prop="commodity_value_quantity" label="商品价值量（万元）" width="200">
              </el-table-column>
              <el-table-column align="center" prop="total_sales_amount" label="销售总额" width="200">
              </el-table-column>
            </el-table>
            <div class="content_footer">
              <el-button round @click="toPage('city_sell')">查看更多</el-button>
            </div>
          </div>
        </div>
        <!-- 全省各单位采购排行 -->
        <div class="content_title" v-if="!loading">
          <div class="c_title">
            <div class="c_title_l">
              <span>{{current_city}}</span>各单位采购排行
              <el-image class="title_img" :src="$img(titlesImg)" fit="cover" />
            </div>
            <div class="c_title_r">
              <el-input placeholder="请输入单位名称搜索" class="input-with-select">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </div>
          </div>
          <div class="c_body">
            <el-row class="elbut_lst">
              <el-button v-for="(item,index) in buttonlst_caigou" :key="index" size="mini" round :type="item.type"
                @click="changeBut_caigou(item)"> {{item.name}}</el-button>
            </el-row>
            <div class="c_b_top">
              <div class="top_item" v-for="(item,index) in tableTitleDatas_caigou" :key="index">
                <p class="number">{{item.value}}</p>
                <p class="names">{{item.name}}{{item.untis}}</p>
              </div>
            </div>
            <!-- 
              company_name: "长沙农商银行"
              total_purchase_amount: "1678940.57"
              treat_the_poor_count: "2566572"
              -->
            <div class="c_b_shuang">
              <div class="cb_item">
                <el-table :data="tableData2_l" style="width: 100%">
                  <el-table-column align="center" prop="address" label="单位名称">
                    <template slot-scope="scope">
                      <div class="table_address_body">
                        <span v-if="scope.$index === 0" class="title_num num1">{{scope.$index + 1}}</span>
                        <span v-if="scope.$index === 1" class="title_num num2">{{scope.$index + 1}}</span>
                        <span v-if="scope.$index === 2" class="title_num num3">{{scope.$index + 1}}</span>
                        <span v-if="scope.$index > 2" class="title_num num0">{{scope.$index + 1}}</span>
                        <span>&nbsp;&nbsp;</span>
                        <el-tooltip placement="bottom">
                          <div slot="content">
                            <div class="tooltip_box">{{scope.row.company_name}}</div>
                          </div>
                          <span class="font_one_row">{{scope.row.company_name}}</span>
                        </el-tooltip>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" prop="treat_the_poor_count" label="带贫人数" width="120">
                  </el-table-column>
                  <el-table-column align="center" prop="total_purchase_amount" label="采购金额" width="120">
                  </el-table-column>
                </el-table>
              </div>
              <div class="cb_item">
                <el-table :data="tableData2_r" style="width: 100%">
                  <el-table-column align="center" prop="address" label="单位名称">
                    <template slot-scope="scope">
                      <div class="table_address_body">
                        <span v-if="(scope.$index + tableData2_l.length) === 0"
                          class="title_num num1">{{scope.$index + tableData2_l.length + 1}}</span>
                        <span v-if="(scope.$index + tableData2_l.length) === 1"
                          class="title_num num2">{{scope.$index + tableData2_l.length + 1}}</span>
                        <span v-if="(scope.$index + tableData2_l.length) === 2"
                          class="title_num num3">{{scope.$index + tableData2_l.length + 1}}</span>
                        <span v-if="(scope.$index + tableData2_l.length) > 2"
                          class="title_num num0">{{scope.$index + tableData2_l.length + 1}}</span>
                        <span>&nbsp;&nbsp;</span>
                        <el-tooltip placement="bottom">
                          <div slot="content">
                            <div class="tooltip_box">{{scope.row.company_name}}</div>
                          </div>
                          <span class="font_one_row">{{scope.row.company_name}}</span>
                        </el-tooltip>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" prop="treat_the_poor_count" label="带贫人数" width="120">
                  </el-table-column>
                  <el-table-column align="center" prop="total_purchase_amount" label="采购金额" width="120">
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="content_footer">
              <el-button round @click="toPage('budget_purchase')">查看更多</el-button>
            </div>
          </div>
        </div>
        <!-- 全省各单位充值排行 -->
        <div class="content_title" v-if="!loading">
          <div class="c_title">
            <div class="c_title_l">
              <span>{{current_city}}</span>各单位充值排行
              <el-image class="title_img" :src="$img(titlesImg)" fit="cover" />
            </div>
            <div class="c_title_r">
              <el-input placeholder="请输入内容" class="input-with-select">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </div>
          </div>
          <div class="c_body">
            <el-row class="elbut_lst">
              <el-button v-for="(item,index) in buttonlst_chongzhi" :key="index" size="mini" round :type="item.type"
                @click="changeBut_chongzhi(item)"> {{item.name}}</el-button>
            </el-row>
            <div class="c_b_top">
              <div class="top_item" v-for="(item,index) in tableTitleDatas_chongzhi" :key="index">
                <p class="number">{{item.value}}</p>
                <p class="names">{{item.name}}{{item.untis}}</p>
              </div>
            </div>
            <el-table :data="tableData_chongzhi" style="width: 100%">
              <el-table-column align="center" prop="address" label="单位名称">
                <template slot-scope="scope">
                  <div class="table_address_body">
                    <span v-if="scope.$index === 0" class="title_num num1">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index === 1" class="title_num num2">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index === 2" class="title_num num3">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index > 2" class="title_num num0">{{scope.$index + 1}}</span>
                    <span>&nbsp;&nbsp;</span>
                    <span v-html="scope.row.address"></span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="renkou" label="已采购比率" width="420">
                <template slot-scope="scope">
                  <div>
                    <el-progress :percentage="scope.row.bi"></el-progress>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="jinger" label="充值金额" width="320">
              </el-table-column>
            </el-table>
            <div class="content_footer">
              <el-button round @click="toPage('budget_recharge')">查看更多</el-button>
            </div>
          </div>
        </div>
        <!-- 全省教育联采排行 -->
        <div class="content_title" v-if="!loading">
          <div class="c_title">
            <div class="c_title_l">
              <span>全省</span>教育联采排行
              <el-image class="title_img" :src="$img(titlesImg)" fit="cover" />
            </div>
          </div>
          <div class="c_body">
            <div class="c_table_3">
              <div class="ctable_item">
                <div class="ctable_item_title">入围供应商名单公示</div>
                <el-table :data="tableLCData_gys" style="width: 100%">
                  <el-table-column align="center" prop="address" label="供应商名称">
                    <template slot-scope="scope">
                      <div class="table_address_body">
                        <span v-if="scope.$index === 0" class="title_num num1">{{scope.$index + 1}}</span>
                        <span v-if="scope.$index === 1" class="title_num num2">{{scope.$index + 1}}</span>
                        <span v-if="scope.$index === 2" class="title_num num3">{{scope.$index + 1}}</span>
                        <span v-if="scope.$index > 2" class="title_num num0">{{scope.$index + 1}}</span>
                        <span>&nbsp;&nbsp;</span>
                        <el-tooltip placement="bottom">
                          <div slot="content">
                            <div class="tooltip_box">{{scope.row.title}}</div>
                          </div>
                          <span class="font_one_row">{{scope.row.title}}</span>
                        </el-tooltip>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" prop="value" label="供应商类别" width="120">
                  </el-table-column>
                </el-table>
              </div>
              <div class="ctable_item">
                <div class="ctable_item_title">采购商排行榜</div>
                <el-table :data="tableLCData_cgs" style="width: 100%">
                  <el-table-column align="center" prop="address" label="采购商">
                    <template slot-scope="scope">
                      <div class="table_address_body">
                        <span v-if="scope.$index === 0" class="title_num num1">{{scope.$index + 1}}</span>
                        <span v-if="scope.$index === 1" class="title_num num2">{{scope.$index + 1}}</span>
                        <span v-if="scope.$index === 2" class="title_num num3">{{scope.$index + 1}}</span>
                        <span v-if="scope.$index > 2" class="title_num num0">{{scope.$index + 1}}</span>
                        <span>&nbsp;&nbsp;</span>
                        <el-tooltip placement="bottom">
                          <div slot="content">
                            <div class="tooltip_box">{{scope.row.title}}</div>
                          </div>
                          <span class="font_one_row">{{scope.row.title}}</span>
                        </el-tooltip>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" prop="value" label="金额（元）" width="120">
                  </el-table-column>
                </el-table>
              </div>
              <div class="ctable_item">
                <div class="ctable_item_title">价格行情</div>
                <el-table :data="tableLCData_money" style="width: 100%">
                  <el-table-column align="center" prop="address" label="商品名称">
                    <template slot-scope="scope">
                      <div class="table_address_body">
                        <span v-if="scope.$index === 0" class="title_num num1">{{scope.$index + 1}}</span>
                        <span v-if="scope.$index === 1" class="title_num num2">{{scope.$index + 1}}</span>
                        <span v-if="scope.$index === 2" class="title_num num3">{{scope.$index + 1}}</span>
                        <span v-if="scope.$index > 2" class="title_num num0">{{scope.$index + 1}}</span>
                        <span>&nbsp;&nbsp;</span>
                        <el-tooltip placement="bottom">
                          <div slot="content">
                            <div class="tooltip_box">{{scope.row.title}}</div>
                          </div>
                          <span class="font_one_row">{{scope.row.title}}</span>
                        </el-tooltip>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" prop="value" label="联采指导价" width="120">
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="content_footer">
              <el-button round @click="toPage('education_combined')">查看更多</el-button>
            </div>
          </div>
        </div>
        <!-- 全省，商家销售榜 -->
        <div class="content_title" v-if="!loading">
          <div class="c_title c_title_center">
            <el-image class="title_img_left" :src="$img(titlesImg)" fit="cover" />
            <span>全省</span>商家销售榜
            <el-image class="title_img" :src="$img(titlesImg)" fit="cover" />
          </div>
          <div class="c_body">
            <div class="cb_box">
              <div class="cb_box_shop_item" v-for="(item,index) in shopsLst" :key="index">
                <div class="itid">{{item.id}}</div>
                <el-tooltip placement="bottom">
                  <div slot="content">
                    <div class="tooltip_box">{{item.title}}</div>
                  </div>
                  <div class="title font_one_row">{{item.title}}</div>
                </el-tooltip>
                <div class="msg">销售额：</div>
                <div class="money_total">{{item.money_total}}</div>
              </div>
            </div>
            <div class="content_footer">
              <el-button round @click="toPage('shop_lst')">查看更多</el-button>
            </div>
          </div>
        </div>
        <!-- 全省，分类和产品销售数据 -->
        <div class="content_title" v-if="!loading">
          <div class="c_title c_title_center">
            <div class="link_box">
              <el-link class="link_item" type="primary" :underline="false" v-for="(item,index) in linkLst"
                :class="item.type === 'on' ? 'link_on' : ''" :key="index" @click="changeLink(item)"> {{item.name}}
              </el-link>
            </div>
          </div>
          <div class="c_body">
            <div class="cb_box">
              <div v-for="(item,index) in goodsLstOn" :key="index"
                :class="linkLst_onItem===0 ? 'cb_box_item_no' : 'cb_box_item_off' ">
                <div class="cb_box_tips" :class="index<3 ? 'cb_box_on' : '' ">{{item.id}}</div>
                <el-image class="cb_box_img" :src="$img(item.img)" fit="cover" />
                <el-tooltip placement="bottom">
                  <div slot="content">
                    <div class="tooltip_box">{{item.title}}</div>
                  </div>
                  <div class="cb_box_title font_one_row">{{item.title}}</div>
                </el-tooltip>
                <div class="cb_box_msg">累计<span class="font_color_waring">{{item.person_count}}</span>人买过</div>
                <div class="cb_box_msg">近一个月售出<span class="font_color_waring">{{item.amount}}</span>件</div>
              </div>
            </div>
            <div class="content_footer">
              <el-button round @click="toPage('list')">查看更多</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import hunan from "@/components/map/hunan"; // 湖南地图
  import {
    getStatisticsFupinproducts,
    getPurchaseGoodsSummaryList,
    getPurchasingUnitRankingList,
    getSalesGoodsSummaryList,
    getPurchaseGoodsSummary
  } from '@/api/bigdata/fupin_catalog'
  import {
    mapGetters
  } from 'vuex';
  export default {
    name: 'bigdataindex',
    components: {
      hunan,
    },
    data: () => {
      return {
        // 湖南14市州数据
        hunan_qx: [{
          title: "长沙市",
          pingying: "changsha",
          city_id: 430100000000,
          currentOn: 0,
        }, {
          title: "常德市",
          pingying: "changde",
          city_id: 430700000000,
          currentOn: 0,
        }, {
          title: "张家界市",
          pingying: "zhangjiajie",
          city_id: 430800000000,
          currentOn: 0,
        }, {
          title: "湘西土家族苗族自治州",
          city_id: 433100000000,
          pingying: "xiangxi",
          currentOn: 0,
        }, {
          title: "怀化市",
          pingying: "huaihua",
          city_id: 431200000000,
          currentOn: 0,
        }, {
          title: "永州市",
          pingying: "yongzhou",
          city_id: 431100000000,
          currentOn: 0,
        }, {
          title: "邵阳市",
          pingying: "shaoyang",
          city_id: 430500000000,
          currentOn: 0,
        }, {
          title: "衡阳市",
          pingying: "henyang",
          city_id: 430400000000,
          currentOn: 0,
        }, {
          title: "郴州市",
          pingying: "chengzhou",
          city_id: 431000000000,
          currentOn: 0,
        }, {
          title: "岳阳市",
          pingying: "yueyang",
          city_id: 430600000000,
          currentOn: 0,
        }, {
          title: "益阳市",
          pingying: "yiyang",
          city_id: 430100000000,
          currentOn: 0,
        }, {
          title: "湘潭市",
          pingying: "xiangtan",
          city_id: 430900000000,
          currentOn: 0,
        }, {
          title: "娄底市",
          pingying: "loudi",
          city_id: 431300000000,
          currentOn: 0,
        }, {
          title: "株洲市",
          pingying: "zhuzhou",
          city_id: 430200000000,
          currentOn: 0,
        }, ],
        current_city: "全省",
        loading: true,
        position_top: false,
        linkLst_onItem: 0,
        tableLCData_gys: [{
          title: "湖南银光粮油股份有限公司",
          value: "大米",
        }, {
          title: "湖南湘西八件宝农业有限责任公司",
          value: "菜油、茶油",
        }, {
          title: "溆浦绿之然农业有限公司",
          value: "米、菜油、茶油",
        }, {
          title: "湖南湘西八件宝农业有限责任公司",
          value: "菜油、茶油",
        }, {
          title: "溆浦绿之然农业有限公司",
          value: "米、菜油、茶油",
        }, {
          title: "溆浦绿之然农业有限公司",
          value: "米、菜油、茶油",
        }, {
          title: "湖南湘西八件宝农业有限责任公司",
          value: "菜油、茶油",
        }, {
          title: "溆浦绿之然农业有限公司",
          value: "米、菜油、茶油",
        }, ],
        tableLCData_cgs: [{
          title: "湘一立信实验学校-锦茂食堂",
          value: "100万",
        }, {
          title: "食品药品业学(含浦校区)-锦茂食堂",
          value: "87万",
        }, {
          title: "湖南工业大学-锦茂食堂",
          value: "67万",
        }, {
          title: "食品药品业学(含浦校区)-锦茂食堂",
          value: "87万",
        }, {
          title: "湖南工业大学-锦茂食堂",
          value: "67万",
        }, {
          title: "长沙理工大学云塘校区-锦茂食堂",
          value: "67万",
        }, {
          title: "湖南工业大学-锦茂食堂",
          value: "67万",
        }, {
          title: "白鹤菱溪中学-锦茂食堂",
          value: "67万",
        }, ],
        tableLCData_money: [{
          title: "大米（帮扶产品）",
          value: "2.36",
        }, {
          title: "鸡蛋（帮扶产品）",
          value: "1.3",
        }, {
          title: "菜籽油（帮扶产品）",
          value: "1.3",
        }, {
          title: "油麦菜",
          value: "1.3",
        }, {
          title: "黄皮土豆",
          value: "1.3",
        }, {
          title: "白花菜",
          value: "1.3",
        }, {
          title: "紫长茄",
          value: "1.3",
        }, {
          title: "西红柿",
          value: "1.3",
        }, ],
        shopsLst: [{
          id: 1,
          title: "湘西果园社农业科技有限责任公司",
          money_total: "123558.34万元",
        }, {
          id: 2,
          title: "湖南大三湘茶油股份有限公司",
          money_total: "38558.34万元",
        }, {
          id: 3,
          title: "长沙市天心区叁农农产品中心",
          money_total: "38558.34万元",
        }, {
          id: 4,
          title: "湖南省城步苗族自治县贤能酥脆枣农民专业种植合作社专业种植合作社专业种植合作社",
          money_total: "38558.34万元",
        }, {
          id: 5,
          title: "城步苗族自治县青钱柳茶旗舰店",
          money_total: "38558.34万元",
        }, {
          id: 6,
          title: "新田县雷叔叔油茶产业发展有限公司",
          money_total: "38558.34万元",
        }, ],
        goodsLstOn: [],
        goodsLst: [{
          id: 1,
          title: "水果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 2,
          title: "水果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 3,
          title: "水果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 4,
          title: "水果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 5,
          title: "水果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 6,
          title: "水果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 7,
          title: "水果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 8,
          title: "水果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 9,
          title: "水果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 10,
          title: "水果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, ],
        goodsLst_2: [{
          id: 1,
          title: "湖南新宁崀山脐橙普通箱9斤小果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 2,
          title: "湘益资压榨菜籽油4.5L",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 3,
          title: "2021新茶萧氏明前新绿250g湖北茶叶绿茶散装高山云雾袋装",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 4,
          title: "湘益资香米50斤",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 5,
          title: "麻阳苗族自治县纯正浓香菜籽油5L现榨现卖食用油包邮",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 6,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 7,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 8,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 9,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, {
          id: 10,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817044131162918969181631.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 0,
        }, ],
        linkLst: [{
          id: 1,
          name: "分类销售数据",
          type: "on",
        }, {
          id: 2,
          name: "产品销售榜",
          type: "",
        }, ],
        buttonlst_caigou: [{
          value: 0,
          type: 'primary',
          name: '不限',
        }, {
          value: 1,
          type: '',
          name: '预算单位',
        }, {
          value: 2,
          type: '',
          name: '非预算单位',
        }, ],
        buttonlst_chongzhi: [{
          id: 1,
          type: 'primary',
          name: '不限',
        }, {
          id: 2,
          type: '',
          name: '预算单位',
        }, {
          id: 3,
          type: '',
          name: '非预算单位',
        }, ],
        tableData2: [{
          address: '湖南生平米业股份有限公司',
          renkou: '1636',
          jinger: '584956.52',
        }, {
          address: '新疆美之羡肉制品有限公司',
          renkou: '1636',
          jinger: '584956.52',
        }, {
          address: '新田县雷叔叔油茶产业发展有限公司',
          renkou: '1636',
          jinger: '584956.52',
        }, {
          address: '湖南新煮艺供应链有限公司',
          renkou: '1636',
          jinger: '584956.52',
        }, {
          address: '湖南惠一科技有限公司',
          renkou: '1636',
          jinger: '584956.52',
        }, {
          address: '新晃侗族自治县振新祖业销售有限公司',
          renkou: '1636',
          jinger: '584956.52',
        }, {
          address: '长沙桃商贸易合伙企业(有限合伙)',
          renkou: '1636',
          jinger: '584956.52',
        }, {
          address: '中智云农业发展有限公司',
          renkou: '1636',
          jinger: '584956.52',
        }, {
          address: '湖南灯塔米业有限公司',
          renkou: '1636',
          jinger: '584956.52',
        }, {
          address: '古丈县三道和茶厂',
          renkou: '1636',
          jinger: '584956.52',
        }, {
          address: '泸溪县云峰大棚果蔬种植农民专业合作社',
          renkou: '1636',
          jinger: '584956.52',
        }, {
          address: '湖南耘睿农业发展有限责任公司',
          renkou: '1636',
          jinger: '584956.52',
        }, {
          address: '益阳市资阳区益资贸易有限公司',
          renkou: '1636',
          jinger: '584956.52',
        }, {
          address: '广西亚比该农业发展有限公司',
          renkou: '1636',
          jinger: '584956.52',
        }, {
          address: '湖南精创富康食品科技有限公司',
          renkou: '1636',
          jinger: '584956.52',
        }, ],
        tableData_chongzhi: [{
          address: '长沙农商银行',
          bi: 99,
          jinger: '584956.52',
        }, {
          address: '湘阴县文星镇城关中学',
          bi: 68,
          jinger: '584956.52',
        }, {
          address: '邵阳县人民医院',
          bi: 78,
          jinger: '584956.52',
        }, {
          address: '湘阴县文星镇城关中学',
          bi: 89,
          jinger: '584956.52',
        }, {
          address: '邵阳县人民医院',
          bi: 19,
          jinger: '584956.52',
        }, {
          address: '邵阳县人民医院',
          bi: 84,
          jinger: '584956.52',
        }, {
          address: '湘阴县文星镇城关中学',
          bi: 89,
          jinger: '584956.52',
        }, {
          address: '邵阳县人民医院',
          bi: 69,
          jinger: '584956.52',
        }, ],
        tableCaigouData: [],
        tableXiaoshouData: [],
        tableDanweiCaigouData: [],
        tableData2_l: [],
        tableData2_r: [],
        backgroundImg: "public/static/img/bj.jpg",
        bigHnImg: "public/static/img/hn.jpg",
        titlesImg: "public/static/img/titles.png",
        currentQu: {
          province_id: 430000000000,
          city_id: 0,
        },
        bigDataTop: [],
        mapData: {
          showList: []
        },
        bigDataTopAll: [],
        tableTitleDatas_caigou: [],
        tableTitleDatas_chongzhi: [{
          name: '单位个数',
          untis: '（个）',
          value: 15,
        }, {
          name: '充值总金额',
          untis: '（万元）',
          value: 20182546.34,
        }, ],
      };
    },
    created() {},
    computed: {
      ...mapGetters(['defaultHeadImage', 'defaultGoodsImage'])
    },
    watch: {
      // 当前市州发生变化时，
      current_city(newData, oldData) {
        if (newData) {
          this.hunan_qx.map((item, index) => {
            item.currentOn = 0;
            if (item.title === newData) {
              // 更改当前区县 city_id 
              this.currentQu.city_id = item.city_id;
              item.currentOn = 1;
              this.changeCurrentCity(item.title);
              this.$refs.mapbox.mapChart(item.pingying);
              // 当前城市区县变化时，更新数据
              /** 更改城市后需要执行的动作 */
              this.changeCityActions();
            }
          })
        }
      }
    },
    mounted() {
      this.changTableData2();
      this.goodsLstOn = this.goodsLst;
      setTimeout(() => {
        this.loading = false;
      }, 500);
      window.addEventListener('scroll', this.handleScroll);
      /** 更改城市后需要执行的动作 */
      this.changeCityActions();
    },
    //销毁,否则跳到别的路由还是会出现
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      /** 更改城市后需要执行的动作 */
      changeCityActions() {
        /**获取扶贫全部数据 */
        this.getStatisticsFupinproducts();
        /**查看区县的数据汇总 */
        this.getPurchaseGoodsSummary();
        /**全省各市州采购排行 */
        this.getPurchaseGoodsSummaryList();
        /**全省预算单位或非预算单位的采购排行 */
        this.getPurchasingUnitRankingList();
        /**市州或区县销售数据排行搒 */
        this.getSalesGoodsSummaryList();
        this.$forceUpdate();
      },
      /** 全省预算单位或非预算单位的采购排行 */
      getPurchasingUnitRankingList() {
        getPurchasingUnitRankingList({
          province_id: this.currentQu.province_id,
          city_id: this.currentQu.city_id,
          is_budget: this.is_budget_caigou,
          page: 1,
          page_size: 12,
        }).then(res => {
          if (res.code == 10000) {
            console.log("......全省预算单位或非预算单位的采购排行...res...", res);
            this.tableDanweiCaigouData = res.result.list;
            this.tableTitleDatas_caigou = [{
              name: '单位个数',
              untis: '（个）',
              value: res.result.unit_conut,
            }, {
              name: '采购总金额',
              untis: '（万元）',
              value: res.result.total_purchase_amount,
            }];
            this.changTableData2();
          }
        }).catch(err => {
          console.log("............err...", err);
        })
      },
      // 分隔返回的数据总表到两个分表中填充
      changTableData2() {
        if (this.tableDanweiCaigouData.length > 0) {
          let ban = Math.ceil(this.tableDanweiCaigouData.length / 2);
          this.tableData2_l = this.tableDanweiCaigouData.slice(0, ban);
          this.tableData2_r = this.tableDanweiCaigouData.slice(ban);
        }
      },
      /** 市州或区县销售数据排行搒 */
      getSalesGoodsSummaryList() {
        getSalesGoodsSummaryList({
          province_id: this.currentQu.province_id,
          city_id: this.currentQu.city_id,
          page: 1,
          page_size: 8,
        }).then(res => {
          if (res.code == 10000) {
            console.log("......市州或区县销售数据排行搒...res...", res);
            this.tableXiaoshouData = res.result.list;
          }
        }).catch(err => {
          console.log("............err...", err);
        })
      },
      /** 全省按区域采购排行 */
      getPurchaseGoodsSummaryList() {
        getPurchaseGoodsSummaryList({
          province_id: this.currentQu.province_id,
          city_id: this.currentQu.city_id,
          page: 1,
          page_size: 8,
        }).then(res => {
          if (res.code == 10000) {
            this.tableCaigouData = res.result.list;
            console.log("......按区域采购排行...res...tableCaigouData", res, this.tableCaigouData);
          }
        }).catch(err => {
          console.log("............err...", err);
        })
      },
      /** 查看区县的数据汇总 */
      /**
       * currentQu: {
       * province_id: 430000000000,
       * city_id: 0,
       * */
      getPurchaseGoodsSummary() {
        getPurchaseGoodsSummary({
          province_id: this.currentQu.province_id,
          city_id: this.currentQu.city_id,
        }).then(res => {
          //   {
          //   name: '平台销售额',
          //   untis: '（万元）',
          //   value: 6752,
          // }, {
          //   name: '供应商',
          //   untis: '（个）',
          //   value: 6752,
          // }, {
          //   name: '带贫人口',
          //   untis: '（万人）',
          //   value: 3184,
          // }, {
          //   name: '帮扶产品',
          //   untis: '（款）',
          //   value: 33380,
          // },
          let resData = res.result;
          this.bigDataTop = [];
          /** 平台销售额（万元） */
          let itemAll_purchase_amount = {
            name: '平台销售额',
            untis: '（万元）',
            value: 0,
          };
          if (parseFloat(resData.purchase_amount) >= 10000) {
            itemAll_purchase_amount.untis = '（亿元）';
            itemAll_purchase_amount.value = (parseFloat(resData.purchase_amount) / 10000).toFixed(2);
          } else {
            itemAll_purchase_amount.untis = '（万元）';
            itemAll_purchase_amount.value = (parseFloat(resData.purchase_amount)).toFixed(2);
          }
          this.bigDataTop.push(itemAll_purchase_amount);
          /**供应商个数 */
          this.bigDataTop.push({
            name: '供应商',
            untis: '（个）',
            value: resData.supplier_count ? resData.supplier_count : 0,
          });
          /** 带贫人口 */
          let itemAll_treat_the_poor_count = {
            name: '带贫人口',
            untis: '（万人）',
            value: 0,
          };
          if (parseFloat(resData.treat_the_poor_count) >= 10000) {
            itemAll_treat_the_poor_count.untis = '（亿人）';
            itemAll_treat_the_poor_count.value = (parseFloat(resData.treat_the_poor_count) / 10000).toFixed(2);
          } else {
            itemAll_treat_the_poor_count.untis = '（万人）';
            itemAll_treat_the_poor_count.value = (parseFloat(resData.treat_the_poor_count)).toFixed(2);
          }
          this.bigDataTop.push(itemAll_treat_the_poor_count);
          /**帮扶产品 */
          this.bigDataTop.push({
            name: '帮扶产品',
            untis: '（款）',
            value: resData.poverty_alleviation_product_count ? resData.poverty_alleviation_product_count : 0,
          });
          this.mapData.showList = res.result.area_list;
          console.log("........区县的数据汇总..res...this.bigDataTop...", res, this.bigDataTop);
        }).catch(err => {
          console.log("............err...", err);
        })
      },
      /** 获取扶贫全部数据 */
      getStatisticsFupinproducts() {
        getStatisticsFupinproducts({}).then(res => {
          //   {
          //   name: '供应商',
          //   untis: '（个）',
          //   value: 6752,
          // }, {
          //   name: '带贫人口',
          //   untis: '（万人）',
          //   value: 3184,
          // }, {
          //   name: '帮扶产品',
          //   untis: '（款）',
          //   value: 33380,
          // }, {
          //   name: '商品价值量',
          //   untis: '（万元）',
          //   value: 6752,
          // }, 
          // supplier_count: 1073
          // treat_the_poor_count: "1879273"
          // commodity_value_quantity: "1879273.00"
          // products_count: 3305
          let resData = res.result;
          this.bigDataTopAll = [];
          /**供应商个数 */
          this.bigDataTopAll.push({
            name: '供应商',
            untis: '（个）',
            value: resData.supplier_count ? resData.supplier_count : 0,
          });
          /** 带贫人口 */
          let itemAll_treat_the_poor_count = {
            name: '带贫人口',
            untis: '（万人）',
            value: 0,
          };
          if (parseFloat(resData.treat_the_poor_count) >= 10000) {
            itemAll_treat_the_poor_count.untis = '（万人）';
            itemAll_treat_the_poor_count.value = (parseFloat(resData.treat_the_poor_count) / 10000).toFixed(2);
          } else {
            itemAll_treat_the_poor_count.untis = '（人）';
            itemAll_treat_the_poor_count.value = resData.treat_the_poor_count;
          }
          this.bigDataTopAll.push(itemAll_treat_the_poor_count);
          /** 帮扶产品（款） */
          this.bigDataTopAll.push({
            name: '帮扶产品',
            untis: '（款）',
            value: resData.products_count ? resData.products_count : 0,
          });
          /** 商品价值量（万元） */
          let itemAll_commodity_value_quantity = {
            name: '商品价值量',
            untis: '（万元）',
            value: 0,
          };
          if (parseFloat(resData.commodity_value_quantity) >= 10000) {
            itemAll_commodity_value_quantity.untis = '（亿元）';
            itemAll_commodity_value_quantity.value = (parseFloat(resData.commodity_value_quantity) / 10000).toFixed(
              2);
          } else {
            itemAll_commodity_value_quantity.untis = '（万元）';
            itemAll_commodity_value_quantity.value = resData.commodity_value_quantity;
          }
          this.bigDataTopAll.push(itemAll_commodity_value_quantity);
          console.log(".......全省概览......bigDataTopAll..", this.bigDataTopAll);
        }).catch(err => {
          console.log(".........err...", err);
        })
      },
      // 点击菜单项后会触发事件
      handleCommand(command) {
        this.hunan_qx.map((item, index) => {
          if (item.pingying === command) {
            this.current_city = item.pingying;
            item.currentOn = 1;
            this.changeCurrentCity(item.title);
            this.$refs.mapbox.mapChart(item.pingying);
            this.$forceUpdate();
          }
        })
      },
      // 滚动到页面高度时，触发
      handleScroll() {
        this.scroll = document.documentElement.scrollTop || document.body.scrollTop
        if (this.scroll >= 400) {
          this.position_top = true;
        } else {
          this.position_top = false;
        }
      },
      // 更改当前市州显示名称
      changeCurrentCity(option) {
        this.current_city = option;
        this.$forceUpdate();
      },
      // 点击全部时，地图返回到省图
      clickMapInit(option = '') {
        if (option === 'top') {
          this.position_top = false;
          document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
        this.current_city = "全省";
        this.currentQu.city_id = 0;
        this.$refs.mapbox.mapChart('hunan');
      },
      // 跳转到对应的链接
      toPage(option) {
        if (option === 'list') {
          switch (this.linkLst_onItem) {
            case 0:
              option = 'category_lst';
              break;
            case 1:
              option = 'goods_lst';
              break;
          }
        } else {}
        this.$router.pushToTab(option)
      },
      // 
      changeLink(option) {
        this.linkLst_onItem = 0;
        this.linkLst.map((item, index) => {
          item.type = '';
          if (item.id === option.id) {
            item.type = 'on';
            this.linkLst_onItem = index;
          }
        })
        if (this.linkLst_onItem === 0) {
          this.goodsLstOn = this.goodsLst;
        } else if (this.linkLst_onItem === 1) {
          this.goodsLstOn = this.goodsLst_2;
        }
      },
      // 修改按钮状态
      changeBut_caigou(option) {
        this.buttonlst_caigou.map((item, index) => {
          item.type = '';
          if (item.value === option.value) {
            this.is_budget_caigou = option.value;
            if(this.is_budget_caigou === 0 ){
              this.is_budget_caigou = '';
            }
            /**全省预算单位或非预算单位的采购排行 */
            this.getPurchasingUnitRankingList();
            item.type = 'primary';
          }
        })
      },
      // 修改按钮状态
      changeBut_chongzhi(option) {
        this.buttonlst_chongzhi.map((item, index) => {
          item.type = '';
          if (item.id === option.id) {
            item.type = 'primary';
          }
        })
      },
    },
  };
</script>
<style lang="scss" scoped>
  $color_font0: #797979;
  $color_font1: #555555;
  $color_font20: #3d3d3d;
  $color_font2: #303030;
  $color_waring: #D81E01;

  .el-main {
    position: relative;
  }

  .page_body {
    width: 100% !important;
    background-color: rgba(247, 247, 247);
    background-image: url('/public/static/img/bj.jpg');
    background-repeat: no-repeat;
    background-size: 100% 360px;
    box-sizing: border-box;
  }

  .box {
    max-width: $width;
    margin: 0 auto;
    padding-bottom: 50px;
    box-sizing: border-box;

    .box_title {
      width: 100%;
      height: 160px;
      display: flex;
      align-items: center;
      box-sizing: border-box;

      .title_p {
        width: 100%;
        font-size: 46px;
        color: #FFFFFF;
        font-weight: 600;
        text-align: center;
        letter-spacing: 4px;
        box-sizing: border-box;
      }
    }

    .tap_span {
      font-size: 16px !important;
      box-sizing: border-box;
      cursor: pointer;
    }

    .tap_span_all span:first-child {
      color: $color_waring !important;
    }

    .content {
      width: 1210px;
      box-sizing: border-box;

      .content_title,
      .content_top {
        padding: 0;
        box-sizing: border-box;
      }

      .content_item {
        padding: 20px;
      }

      .content_item,
      .content_top,
      .content_title {
        margin: 20px auto 0;
        width: 1210px;
        box-sizing: border-box;
        display: block;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        .content_body {
          width: 100%;
          display: flex;
          box-sizing: border-box;

          .body_l {
            width: 580px;
            height: 420px;
            text-align: center;
            box-sizing: border-box;

            .img {
              width: 420px;
              height: 420px;
            }
          }

          .body_r {
            margin-top: 168px;
            width: 620px;
            height: 220px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            flex-wrap: wrap;
            box-sizing: border-box;

            .item {
              width: 300px;
              height: 98px;
              box-sizing: border-box;

              .number {
                text-align: center;
                font-size: 36px;
                font-weight: 800;
                color: rgba(114, 114, 114);
                font-stretch: condensed;
              }

              .names {
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                color: rgba(114, 114, 114);
              }
            }
          }
        }
      }

      .content_title {
        box-sizing: border-box;

        .c_title {
          height: 108px;
          line-height: 68px;
          font-size: 24px;
          font-weight: 600;
          background-color: rgba(232, 232, 232);
          padding: 20px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;

          .c_title_l {
            display: flex;
            align-items: center;

            span {
              color: #ff0000;
            }

            .title_img {
              margin-left: 20px;
              width: 26px !important;
              height: 20px !important;

              img {
                width: 26px !important;
                height: 20px !important;
              }
            }
          }

          .c_title_r {
            width: 420px;
          }
        }

        .c_title_center {
          display: flex;
          justify-content: center;
          align-items: center;

          .title_img_left,
          .title_img {
            margin-left: 20px;
            margin-right: 20px;
            width: 26px !important;
            height: 20px !important;

            img {
              width: 26px !important;
              height: 20px !important;
            }
          }

          .link_box {
            width: 100%;
            display: flex;
            justify-content: center;

            .link_item {
              font-size: 20px;
              margin: 0 30px;
              font-weight: 800;
              color: $color_font2;
            }

            .link_on {
              color: $color_waring;
            }
          }
        }

        .c_body {
          background-color: #FFFFFF;
          padding: 20px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          box-sizing: border-box;

          .cb_box {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .cb_box_shop_item {
              width: 368px;
              margin: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-content: flex-start;
              align-items: flex-start;
              // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
              padding: 12px 10px 12px 40px;
              border-radius: 10px;
              position: relative;
              box-sizing: border-box;

              .itid {
                width: 48px;
                height: 38px;
                font-size: 38px;
                line-height: 38px;
                font-weight: 800;
                color: $color_waring;
                border-bottom: 3px $color_waring solid;
                text-align: center;
              }

              .title {
                font-size: 18px;
                font-weight: 700;
                margin-top: 8px;
              }

              .msg {
                font-size: 12px;
                color: $color_font1;
                margin-top: 8px;
              }

              .money_total {
                font-size: 16px;
                color: $color_waring;
              }
            }

            .cb_box_item_no {
              width: 208px;
              margin: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-content: center;
              align-items: center;
              padding: 12px 0;
              box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
              border-radius: 10px;
              position: relative;
              box-sizing: border-box;

              .cb_box_title {
                width: 100%;
                text-align: center;
                margin-top: 8px;
                height: 22px;
                line-height: 22px;
                font-size: 16px;
                font-weight: 600;
              }

              .cb_box_msg {
                width: 100%;
                text-align: center;
                line-height: 22px;
                font-size: 12px;
              }

              .cb_box_tips {
                position: absolute;
                top: 10px;
                left: 32px;
                z-index: 100;
                background-color: $color_font0;
                width: 42px;
                height: 42px;
                line-height: 42px;
                text-align: center;
                font-size: 24px;
                font-weight: 700;
                border-radius: 50%;
                color: #FFFFFF;
              }

              .cb_box_on {
                background-color: $color_waring;
              }

              .cb_box_img {
                width: 108px;
                height: 108px;
                border-radius: 50%;
              }
            }

            .cb_box_item_off {
              width: 208px;
              margin: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-content: center;
              align-items: flex-start;
              padding: 12px 0;
              border-radius: 10px;
              box-sizing: border-box;

              .cb_box_title {
                width: 92%;
                text-align: left;
                margin-top: 8px;
                padding-left: 8px;
                padding-right: 8px;
                height: 22px;
                line-height: 22px;
                font-size: 16px;
                font-weight: 600;
              }

              .cb_box_msg {
                width: 100%;
                text-align: left;
                line-height: 22px;
                font-size: 12px;
              }

              .cb_box_tips {
                top: 10px;
                left: 32px;
                z-index: 100;
                width: 42px;
                height: 42px;
                line-height: 42px;
                text-align: left;
                font-size: 24px;
                font-weight: 700;
                color: $color_waring;
                border-bottom: 3px $color_waring solid;
              }

              .cb_box_img {
                width: 198px;
                height: 198px;
              }
            }
          }

          .c_b_shuang {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .cb_item {
              width: 46%;
            }
          }

          .c_b_top {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin: 40px 0 30px 0;

            .top_item {
              width: 288px;
              display: flex;
              flex-direction: column;

              .number {
                text-align: center;
                font-size: 38px;
                font-weight: 800;
                color: rgba(114, 114, 114);
                font-stretch: condensed;
                line-height: 40px;
              }

              .names {
                text-align: center;
                line-height: 24px;
              }
            }
          }

          .c_table_3 {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .ctable_item {
              width: 32%;
              border: 1px solid #ebebeb;
              border-radius: 3px;
              transition: .2s;

              .ctable_item_title {
                width: 100%;
                text-align: center;
                margin-top: 12px;
                height: 58px;
                font-size: 18px;
                font-weight: 600;
                color: $color_font1;
                line-height: 58px;
              }
            }
          }

          .content_footer {
            width: 100%;
            height: 108px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .content_top {
        box-sizing: border-box;

        .ct_title {
          height: 138px;
          line-height: 58px;
          font-size: 24px;
          font-weight: 600;
          background-color: rgba(232, 238, 247);
          padding: 20px 20px 0 20px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          box-sizing: border-box;

          .ct_t_title {
            // color: $color_waring;
            color: #747474;
            margin-bottom: 16px;
          }

          .ct_t_content {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .ct_t_msg {
              width: 1000px;
              display: flex;
              justify-content: flex-start;

              .item {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                align-content: flex-end;
                margin-right: 50px;

                .names {
                  font-size: 14px;
                  color: $color_font1;
                  font-weight: 500;
                  line-height: 18px;
                }

                .number {
                  font-size: 22px;
                  color: $color_waring;
                  margin: 0 0 0 8px;
                  font-weight: 900;
                  line-height: 24px;
                }
              }
            }

            .ct_t_a {
              width: 120px;
              height: 18px;
              font-size: 16px;
              line-height: 18px;
              font-weight: 500;
            }
          }
        }

        .ct_body {
          background-color: #FFFFFF;
          padding: 20px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          box-sizing: border-box;
        }
      }
    }
  }

  .elbut_lst {
    display: flex;
    justify-content: center;
  }

  .table_address_body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .title_num {
      width: 42px;
      font-size: 24px;
      font-weight: 800;
      text-align: right;
      padding-bottom: 5px;
    }

    .num0 {
      color: rgb(89, 74, 74);
    }

    .num1 {
      color: rgb(252, 1, 1);
    }

    .num2 {
      color: rgb(253, 200, 3);
    }

    .num3 {
      color: rgb(90, 206, 1);
    }
  }

  .font_color_waring {
    color: $color_waring;
    margin: 0 4px;
  }

  .tooltip_box {
    max-width: 260px;
  }

  .font_one_row {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .ct_position_top {
    width: 1210px;
    margin: 0 auto;
    position: fixed;
    z-index: 999;
    top: 0px;
    background-color: rgba(232, 238, 247);
    display: flex;
    justify-content: space-between;

    .ct_t_left {
      display: flex;
      align-items: center;
      justify-items: flex-start;
      height: 64px;
      padding-left: 30px;
    }

    .ct_t_msg {
      display: flex;
      padding: 20px 30px;
      justify-content: flex-start;

      .item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        align-content: flex-end;
        margin-right: 50px;

        .names {
          font-size: 14px;
          color: $color_font1;
          font-weight: 500;
          line-height: 18px;
        }

        .number {
          font-size: 22px;
          color: $color_font1;
          margin: 0 0 0 8px;
          font-weight: 900;
          line-height: 24px;
        }
      }

      .item:last-child {
        margin-right: 0;
      }
    }
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .currentClass {
    color: #ff0000;
  }
</style>